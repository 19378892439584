const apiUrl = process.env.REACT_APP_API_URI || 'localhost:8080';

const getCounter = async () => {
  const response = await fetch(apiUrl);
  const json = await response.json();
  return json.hits as number;
};

const incrementCounter = async () => {
  const response = await fetch(apiUrl, { method: 'POST' });
  const json = await response.json();
  return json.hits as number;
};

export { getCounter, incrementCounter };
