import { useEffect, useState } from 'react';
import { incrementCounter } from './api';

function Counter() {
  const [hits, setHits] = useState(0);

  useEffect(() => {
    incrementCounter().then((count) => setHits(count));
  }, []);

  return <p>{hits}</p>;
}

export default Counter;
